import { useEffect, useState } from 'react';

const formatter = (price: number) => {
  const data = price.toString().split('.');
  return data[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (data[1] ? '.' + data[1] : '');
};

let need = 0;
let webApp: any = null;
let initData: any = null;
// const api = 'http://localhost:4100/v1';
const api = 'https://api.fastix.biz/v1';

const App = () => {
  const [page, setPage] = useState('none');
  const [tab, setTab] = useState('Bonus');
  const [single, setSingle] = useState<any>({});
  const [place, setPlace] = useState<any>([]);
  const [games, setGames] = useState<any[]>([]);
  const [user, setUser] = useState<any>(null);
  const [current, setCurrent] = useState(0);
  const [achieve, setAchieve] = useState(0);
  const [array, setArray] = useState<{ x: number; y: number }[]>([]);

  const taptap = (e: any) => {
    if (current <= 0) {
      return;
    }

    // const pos = (e.clientX > window.innerWidth / 2 ? 'right' : 'left') + (e.clientY > window.innerHeight / 2 ? 'bottom' : 'top');

    setCurrent(current - 1);
    setAchieve(achieve + 1);
    setArray((array) => [...array, { x: e.clientX, y: e.clientY }]);

    need += 1;
  };

  const listFriends = () => {
    const rows: any = [];

    user?.invites?.forEach((e: any, i: number) => {
      rows.push(
        <i key={i}>
          {i}. {e}
        </i>,
      );
    });

    if (rows.length === 0) {
      return <div className='infoAir empty'>There is no any friend</div>;
    }

    return rows;
  };

  const goPlacer = (index: number, value: string) => {
    setPlace((prevState: any) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const listSingle = () => {
    const rows: any = [];

    single.bets?.forEach((e: any, i: number) => {
      const bets = e.bets.split('*');

      const inner: any = [];
      bets?.forEach((b: any, j: number) => {
        inner.push(
          <span key={j} className={place[e.name] === b ? 'active' : ''} onClick={() => goPlacer(e.name, b)}>
            {b}
          </span>,
        );
      });

      rows.push(
        <div className='singlegame' key={i}>
          <span>{e.name}</span>
          <div>{inner}</div>
        </div>,
      );
    });

    if (rows.length === 0) {
      return <div className='infoAir empty'>There is no any games</div>;
    }

    return rows;
  };

  const listGames = () => {
    const rows: any = [];

    games?.forEach((e, i) => {
      rows.push(
        <div
          className='game'
          key={i}
          onClick={() => {
            setPage('singleGame');
            setSingle(e);
          }}
        >
          <span>{e.title}</span>
          <span>{e.status}</span>
        </div>,
      );
    });

    if (rows.length === 0) {
      return <div className='infoAir empty'>There is no any games</div>;
    }

    return rows;
  };

  const listShow = () => {
    const rows: any = [];
    array.forEach((e, i) => {
      rows.push(<i key={i} style={{ left: e.x, top: e.y - 40 }}></i>);
    });

    return rows;
  };

  const submitBet = async () => {
    try {
      if (Object.values(place).length !== single.bets.length || 1 === 1) {
        alert('Select all bets');
        return;
      }

      const res = await fetch(`${api}/bet`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: 'telegram-' + initData,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const reloading = async () => {
    try {
      if (need > 0) {
        fetch(`${api}/user/add/`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: 'telegram-' + initData,
          },
          body: JSON.stringify({
            value: need,
          }),
        })
          .then((_e) => {})
          .catch((_e) => {});

        need = 0;
      } else {
        const res = await fetch(`${api}/user`, {
          headers: {
            'content-type': 'application/json',
            authorization: 'telegram-' + initData,
          },
        });

        const data = (await res.json()).data;

        setUser(data);
        setCurrent(data.current);
        setAchieve(data.achieve);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getGames = async () => {
    try {
      const res = await fetch(`${api}/game`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          authorization: 'telegram-' + initData,
        },
      });

      const data = (await res.json()).data;

      setGames(data);
    } catch (e) {
      console.log(e);
    }
  };

  const init = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const tgWebAppStartParam = urlParams.get('tgWebAppStartParam');

      const res = await fetch(`${api}/user/telegram`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: 'telegram-' + initData,
        },
        body: JSON.stringify({
          referral: tgWebAppStartParam || null,
        }),
      });

      const data = (await res.json()).data;

      setUser(data);
      setCurrent(data.current);
      setAchieve(data.achieve);

      await getGames();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let timer: any = null;

    const app = (window as any).Telegram?.WebApp;
    if (app) {
      app.ready();
      app.setBackgroundColor('#082D1E');
      app.setHeaderColor('#082D1E');

      webApp = app.initDataUnsafe;
      initData = app.initData;

      // initData =
      //   'user=%7B%22id%22%3A887196069%2C%22first_name%22%3A%22M%22%2C%22last_name%22%3A%22F%22%2C%22username%22%3A%22MFNewAccount%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=6949674367943477888&chat_type=sender&start_param=1&auth_date=1723453016&hash=318d981418969992380ed8c334d2e86558c4137dd282fb48c9ab1df4799e0e8d';
      // webApp = {
      //   chat_instance: 1,
      //   user: {
      //     hash: 'b',
      //     id: 887196069,
      //     first_name: 'j',
      //     last_name: 'k',
      //   },
      // };

      if (!webApp.chat_instance) {
        alert('Error');
      } else {
        init()
          .then(() => {
            timer = setInterval(() => {
              reloading();
            }, 2000);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setArray([]);
  }, [tab]);

  return user ? (
    <div className='airFlex'>
      <div className='header'>
        <h1>
          Level: 1 - Basic<i>Next Level: Pro</i>
        </h1>
        <div className='power'>
          <i>Power: 1</i>
          <span>
            <b style={{ width: '20%' }}></b>
          </span>
        </div>
      </div>
      {page === 'singleGame' ? (
        <>
          <div className='titleLarge'>
            <div className='back' onClick={() => setPage('')}>
              « Back
            </div>
            <p>{single.title}</p>
          </div>
          <div className='allSingle'>{listSingle()}</div>
          <div className='sendData' onClick={() => submitBet()}>
            Submit Bets
          </div>
        </>
      ) : tab === 'Games' ? (
        <>
          <p className='infoAir'>Play And Win 5.000.000 Toman!</p>
          <div className='yourFriends'>{listGames()}</div>
        </>
      ) : tab === 'Live' ? (
        <>
          <div className='yourFriends'>
            <p className='infoAir'>Coming soon...</p>
          </div>
        </>
      ) : tab === 'Setting' ? (
        <>
          <div className='yourFriends'>
            <p className='infoAir'>Coming soon...</p>
          </div>
        </>
      ) : tab === 'Bonus' ? (
        <>
          <div
            onClick={() => {
              setPage('');
              setTab('Games');
            }}
            className='buyVoucher'
          >
            Join Our Competition!
          </div>
          <p className='currentAir'>{formatter(achieve || 0)}</p>
          <p className='currentPower'>
            {formatter(current || 0)} <i> / {formatter(user.max || 0)}</i>
          </p>
          <div className='clickParent'>
            <div className='uwalletClicker' onClick={(e) => taptap(e)}>
              <div className='uwalletClickerIn'>{listShow()}</div>
            </div>
          </div>
        </>
      ) : (
        <>
          <p className='infoAir'>
            Share your invite link with your friends
            <br />
            and get more bonus!
            <a target='_blank' href={'https://t.me/FastixBizBot/Fastix?startapp=' + webApp?.user?.id + ''}>
              https://t.me/FastixBizBot/Fastix?startapp={webApp?.user?.id}
            </a>
          </p>
          <div className='yourFriends'>{listFriends()}</div>
        </>
      )}
      <div className='tabs'>
        <span
          className={tab === 'Bonus' ? 'active' : ''}
          onClick={() => {
            setPage('');
            setTab('Bonus');
          }}
        >
          Miner
        </span>
        <span
          className={tab === 'Games' ? 'active' : ''}
          onClick={() => {
            setPage('');
            setTab('Games');
          }}
        >
          Games
        </span>
        <span
          className={tab === 'Live' ? 'active' : ''}
          onClick={() => {
            setPage('');
            setTab('Live');
          }}
        >
          Live
        </span>
        <span
          className={tab === 'Referral' ? 'active' : ''}
          onClick={() => {
            setPage('');
            setTab('Referral');
          }}
        >
          Friends
        </span>
        <span
          className={tab === 'Setting' ? 'active' : ''}
          onClick={() => {
            setPage('');
            setTab('Setting');
          }}
        >
          Setting
        </span>
      </div>
    </div>
  ) : (
    <div className='airFlex'>
      <div className='loading'>Loading...</div>
    </div>
  );
};

export default App;
